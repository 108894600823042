import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import api from "../api/api";
import { apartamentos } from "../utils/apartamentos"; // Importa as opções de apartamento
import { requestResetPassword } from "./../api/authService";
const PageContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020;
  text-align: center;
  margin-bottom: 40px;
`;

const SearchInput = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
`;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, [search]);

  const fetchUsers = async () => {
    try {
      const response = await api.get(`/api/users/getAll?name=${search}`);
      setUsers(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuários", error);
    }
  };

  const handleResetPassword = async (email) => {
    try {

      const response = await requestResetPassword(email);

      if (response.status == 200) {
        setSuccessMessage(
          "E-mail para redefinição enviado verifique caixa de email."
        );
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error("Erro ao logar", error);
      setErrorMessage(
        error.response.data.error
          ? error.response.data.error
          : "Ocorreu um erro"
      );
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Tem certeza de que deseja excluir este usuário?")) {
      try {
        await api.remove(`/api/users/deleteUser/${userId}`);
        setSuccessMessage("Usuário excluído com sucesso!");
        fetchUsers();
      } catch (error) {
        console.log(error.response.status);
        console.log(error.response.data);
        if (error.response.status !== 500) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Erro ao excluir o usuário!");
        }
      }
    }
  };

  const handleSaveUser = async () => {
    if (!validateForm()) return;

    const formattedPhone = selectedUser.telefone.replace(/\D/g, ""); // Remove a formatação do telefone

    try {
      await api.put(`/api/users/${selectedUser._id}`, {
        ...selectedUser,
        telefone: formattedPhone,
      });
      setSuccessMessage("Usuário atualizado com sucesso!");
      setOpenEdit(false);
      fetchUsers();
    } catch (error) {
      setErrorMessage("Erro ao atualizar o usuário!");
    }
  };

  const validateForm = () => {
    const { name, email, cpf, apartamento, torre, telefone, perfil } =
      selectedUser;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const cpfRegex = /^\d{11}$/;
    const phoneRegex = /^\(\d{2}\)\s\d{4,5}-\d{4}$/;

    if (
      !name ||
      !email ||
      !cpf ||
      !apartamento ||
      !torre ||
      !telefone ||
      !perfil
    ) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return false;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage("E-mail inválido.");
      return false;
    }
    if (!cpfRegex.test(cpf.replace(/\D/g, ""))) {
      setErrorMessage("CPF inválido. Apenas números.");
      return false;
    }
    if (!phoneRegex.test(telefone)) {
      setErrorMessage("Formato de telefone inválido.");
      return false;
    }
    return true;
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedUser(null);
  };

  return (
    <PageContainer>
      <Title>Gestão de Usuários</Title>
      <SearchInput
        label="Pesquisar por nome"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        variant="outlined"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Apartamento</TableCell>
              <TableCell>Torre</TableCell>
              <TableCell>Perfil</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.cpf}</TableCell>
                <TableCell>{user.telefone}</TableCell>
                <TableCell>{user.apartamento}</TableCell>
                <TableCell>{user.torre}</TableCell>
                <TableCell>{user.perfil}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleEditUser(user)}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Excluir
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleResetPassword(user.email)}
                  >
                    Redefinir Senha
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal de edição */}
      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Editar Usuário</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome"
            value={selectedUser?.name || ""}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                name: e.target.value.toUpperCase(),
              })
            }
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="E-mail"
            value={selectedUser?.email || ""}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                email: e.target.value.toLocaleLowerCase().trim(),
              })
            }
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="CPF"
            value={selectedUser?.cpf || ""}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                cpf: e.target.value.replace(/\D/g, ""),
              })
            }
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Telefone"
            value={selectedUser?.telefone || ""}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                telefone: e.target.value
                  .replace(/\D/g, "")
                  .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
              })
            }
            required
            fullWidth
            margin="normal"
          />
          <Select
            label="Apartamento"
            value={selectedUser?.apartamento || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, apartamento: e.target.value })
            }
            required
            fullWidth
            margin="normal"
          >
            {apartamentos.map((apart) => (
              <MenuItem key={apart} value={apart}>
                {apart}
              </MenuItem>
            ))}
          </Select>
          <Select
            label="Torre"
            value={selectedUser?.torre || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, torre: e.target.value })
            }
            required
            fullWidth
            margin="normal"
          >
            <MenuItem value="Acqua">Acqua</MenuItem>
            <MenuItem value="Natura">Natura</MenuItem>
          </Select>
          <Select
            label="Perfil"
            value={selectedUser?.perfil || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, perfil: e.target.value })
            }
            required
            fullWidth
            margin="normal"
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Morador">Morador</MenuItem>
            <MenuItem value="Visitante">Visitante</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancelar</Button>
          <Button variant="contained" onClick={handleSaveUser}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mensagens de Sucesso e Erro */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default UserList;
